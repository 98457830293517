import React from 'react'
import Layout from '../components/layout'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Seo from '../components/seo'
import SmallSpacer from '../components/spacer/small-spacer'
import Spacer from '../components/spacer/spacer'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import NewsList from '../components/news/news-list'
import { graphql } from 'gatsby'
import SubTitle from '../components/scaling-banner/sub-title'

function Svg() {
    return (
        <svg width="984" height="350" viewBox="0 0 984 350" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M168.834 32.8333H123.659V196.324L87.0887 32.8333H37.6113V334H82.7863V170.51L119.357 334H168.834V32.8333Z" fill="#EEEAD9" />
            <path d="M434.22 29.8368H338.832V291.687H434.22V252.409H378.109V176.473H430.479V137.195H378.109V69.1143H434.22V29.8368Z" fill="#EEEAD9" />
            <path d="M620.241 173.594L606.577 31.2342H571.623L596.091 253.671H638.989L651.7 145.312L664.41 253.671H707.309L731.777 31.2342H696.823L683.159 173.594L668.541 31.2342H634.858L620.241 173.594Z" fill="#EEEAD9" />
            <path d="M909.528 60.4299C909.528 58.5674 910.593 57.7692 912.189 57.7692H925.492C927.089 57.7692 928.153 58.5674 928.153 60.4299V95.0189H956.09V60.4299C956.09 43.1353 942.787 29.8319 925.492 29.8319H912.189C894.894 29.8319 881.591 43.1353 881.591 60.4299V80.3851C881.591 98.7439 887.977 108.056 896.491 118.699L918.841 146.902C924.96 154.885 928.153 158.876 928.153 170.849V188.143C928.153 190.006 927.089 190.804 925.492 190.804H912.189C910.593 190.804 909.528 190.006 909.528 188.143V150.894H881.591V188.143C881.591 206.768 893.564 218.741 912.189 218.741H925.492C944.117 218.741 956.09 206.768 956.09 188.143V168.188C956.09 149.031 947.576 138.388 938.53 126.947L916.712 99.0099C911.923 92.6243 909.528 89.6975 909.528 77.7244V60.4299Z" fill="#EEEAD9" />
        </svg>

    )
}
export default function News({ data }) {


    return (
        <Layout>
            <Seo title='News' />
            <ScalingBanner video={data.datoCmsNewsPage.videoLink} image={data.datoCmsNewsPage.heroImage} svg={<Svg />} title='news' >
                <div className="flex flex-end">
                    <SubTitle title='& updates ' />
                </div>
            </ScalingBanner>
            <Spacer className='m-hide' />
            <SmallSpacer />
            <NewsList featured={data.datoCmsNewsPage.featured} news={data.datoCmsNewsPage.news} />
            <Spacer className='m-hide' />
            <SquareScalingBanner image={data.contactPage.heroImage} link='/contact'>
                <h3 className='small-serif'>Next Page</h3>
                <SmallSpacer />
                <h2 className='h1 m-h3'>Contact</h2>
            </SquareScalingBanner>
            <Spacer />

        </Layout>
    )
}
export const query = graphql`
query MyQuery {
    datoCmsNewsPage {
        heroImage {
            url
        }
        videoLink
        news:newsArticles{
            name
            date(formatString: "MM:DD:YYYY")
            slug
            image{
                gatsbyImageData
            }
            category{
                name
            }
            ...NewsFeatured
        }
        featured:featuredNewsArticle{
            name
            slug
            image{
                gatsbyImageData
            }
            category{
                name
            }
            ...NewsFeatured
        }
    }
  contactPage: datoCmsContactPage {
    heroImage {
      gatsbyImageData
    }
  }
 
  
}

`



