import React, { useState } from 'react'

import { chunk } from 'lodash'
import SmallSpacer from '../spacer/small-spacer'
import Star from '../../icons/star'
import { GatsbyImage } from 'gatsby-plugin-image'
import ContentContainer from '../content-container/content-container'
import ArrowLink from '../arrow-link/arrow-link'
import SquareScalingBanner from '../square-scaling-banner/square-scaling-banner'
import RightArrow from '../../icons/right-arrow'
import LeftArrow from '../../icons/left-arrow'
import NewsFeatured from './news-featured'
import Link from '../link'
import FadeInView from '../fade-inview/fade-inview'


export default function NewsList({ news, featured }) {
    const newsLists = chunk(news, 6)
    const [currentList, setCurrentList] = useState(0)


    function changeList(to) {
        window.scrollTo(0, window.innerHeight)
        setCurrentList(to)
    }
    function prev() {
        changeList((state) => { return (state === 0 ? 0 : state - 1) })

    }
    function next() {
        changeList((state) => { return (newsLists.length + 1 === state ? state : state + 1) })
    }

    return (
        <>
            <div className='news--lists--wrapper'>
                <NewsListPage newsList={newsLists[currentList].slice(0, 3)} />
                <FeaturedNews newsItem={featured} />
                <NewsListPage newsList={newsLists[currentList].slice(3, 7)} />

                <SmallSpacer />
            </div>
            <SmallSpacer />
            <SmallSpacer />
            {(news.length > 6 ?
                <ContentContainer>
                    <NewsControls newsLists={newsLists} prev={prev} next={next} setCurrentList={changeList} currentList={currentList} />
                </ContentContainer>
                :
                ''
            )}
        </>

    )
}

function NewsListPage({ newsList }) {
    return (

        <div>
            {newsList.map((newsItem) => {
                return <NewsItem newsItem={newsItem} />
            })}
        </div>
    )
}

function NewsItem({ newsItem }) {
    return (
        <ContentContainer>
            <FadeInView>
                <div className='news--item--wrapper '>
                    <Link to={`/news/${newsItem.slug}/`} className='parent--hover--enabled'>
                        <SmallSpacer />
                        <SmallSpacer className='m-show' />
                        <div className='news--item '>
                            <div className='flex  flex-c'>
                                <div className='flex al-i-c'>
                                    <Star fill='#ff6326 ' />
                                    <h4 className='small-serif orange category ml5'>{newsItem.category.name}</h4>
                                </div>
                            </div>
                            <SmallSpacer className='m-show' />
                            <SmallSpacer className='m-show' />


                            <div className='m-flex flex-c'>
                                <div className='image-inline float-left max-200'>
                                    <div>
                                        <GatsbyImage alt="" className=' mr10 r6' image={newsItem.image.gatsbyImageData} />
                                    </div>

                                </div>
                                <SmallSpacer className='m-show' />
                                <h3 className='text-suede news--title'>  {newsItem.name}</h3>
                            </div>
                            <SmallSpacer className='m-show' />
                            <div className='flex  m-flex-start al-i-start flex-end'>
                                <ArrowLink className='text-suede' name='read more' />
                            </div>
                        </div>
                        <SmallSpacer />
                        <SmallSpacer className='m-show' />
                    </Link>
                </div>
            </FadeInView>
        </ContentContainer>
    )
}


function FeaturedNews({ newsItem }) {
    return (
        <>
            <div>
                <SmallSpacer />
                <SquareScalingBanner container='m-p0 m-r0 ' video={newsItem.featuredVideoUrl} image={newsItem.image} link={`/news/${newsItem.slug}/`} wrapperClassName="al-i-start pl12p   parent--hover--enabled m-pl15 ">
                    <NewsFeatured newsItem={newsItem} />
                </SquareScalingBanner>

            </div>
            <SmallSpacer />
        </>
    )
}



function NewsControls({ newsLists, currentList, setCurrentList, next, prev }) {
    return (
        <div className='news--controls'>
            <div className='flex flex-start al-i-c'>
                <div>
                    <button onClick={prev}>
                        <RightArrow />
                    </button>
                </div>

            </div>
            <div className='pagination--controls'>
                {newsLists.map((list, index) => {
                    return <button onClick={() => { setCurrentList(index) }} className={'small-serif news--nav--item orange ' + (currentList === index ? 'selected' : '')}>{index + 1}</button>
                })}
            </div>
            <div className='flex flex-end al-i-c'>
                <div>
                    <button onClick={next}>
                        <LeftArrow />
                    </button>
                </div>


            </div>
        </div>
    )
}  